@import-normalize;

html {
	font-size: 17px;
	min-height: 100%;
  scroll-behavior: smooth;
}

*,
*::before,
*::after{
box-sizing:border-box;}
a{text-decoration:none; color:inherit; cursor:pointer; display: block;}
button{background-color:transparent; color:inherit; border-width:0; padding:0; cursor:pointer;}
figure{margin:0;}
input::-moz-focus-inner {border:0; padding:0; margin:0;}
ul, ol, dd{margin:0; padding:0; list-style:none;}
h1, h2, h3, h4, h5, h6{margin:0; font-size:inherit; font-weight:inherit;}
p{margin:0;}
cite {font-style:normal;}
fieldset{border-width:0; padding:0; margin:0;}

body {
  margin: 0;
  min-height: 100vh;
  font-family: "SF Pro Text","Myriad Set Pro","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Normalize input hilighting */
input:focus,
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

/* Normalize autofill background color */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #D4EAF0 inset !important;
}
